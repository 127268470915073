import React from "react"
import Layout from "../../components/layout"

export default function TeamGirlsU14() {

    
  return ( 
    <Layout>
    <section className="container">
    <h1>Girls Under 14's</h1>
    <div className="row mb-2">
        <div className="col-md-8">
            <div className="row">
            <img src="/girls-u14.jpg" className="img-fluid mb-4 team-page-hero" />
            </div>
            <div className="row mt-4 mb-4">
                <p>Objective is always to have fun and develop a strong team ethos. Training focuses on all aspects of individual basic skills and tactical awareness. New players are always welcome to join our group.</p>
                <p>Every team has Managers, Coaches or Assistants who are either FA or UEFA qualified. In addition all staff are CRC checked with at least one First Aid qualified member present at all times.</p>
            </div>
        </div>

        <div className="col-md-4">
            <div className="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
            <div className="col p-4 d-flex flex-column position-static">
                <h3 className="lcj-color">Team Information</h3>
                <dl>
                <dt>Age Range</dt>
                <dd>School Year 9, DoB 01/09/2008 - 31/08/2009</dd>
                <dt>Manager(s)</dt>
                <dd>Becky Crowther<br/>
                Mick Ward</dd>
                <dt>Training</dt>
                <dd>Wednesday 5:30-6:30pm on the Astro at AWMC</dd>
                <dt>League</dt>
                <dd>Westriding Girls League. Matches: Saturday Mornings</dd>
                <dt>Join the team</dt>
                <dd><a href="mailto:rebeccac.13@btinternet.com">rebeccac.13@btinternet.com</a><br/>
                <a href="mailto:Michael.ward@nerveagency.co.uk">Michael.ward@nerveagency.co.uk</a>
                </dd>
                </dl>
            </div>
            </div>

            <div className="row">
            <div className="col">
                <div className="card shadow-sm">
                <div className="card-header">Team Sponsor</div>
                <img src="/sponsor-sportsbulksuppliers.png" />
                <div className="card-body">
                    <p className="card-text">Sports Bulk Suppliers<br/><i>Clothing and equipment for teams</i></p>
                    <div className="btn-group">
                    <a href="https://www.sportsbulk.co.uk" target="_blank" className="btn btn-sm btn-outline-secondary">View Sponsor</a>
                    </div>
                </div>
                </div>
            </div>    
            </div>
        </div>
    </div>
    </section>
    </Layout>
  );
}

